import React, { useState, useEffect, useRef } from 'react'
import checkIcon from '../../../svg/check.svg'
import closeIcon from '../../../svg/cancel.svg'
import MailchimpSubscribe from "react-mailchimp-subscribe"

const Form = ({ status, onValidated, closeDialog }) => {
  const input = useRef(null)
  const [inputValue, setInputValue] = useState('')
  const [onError, setOnerror] = useState(false)

  useEffect(() => {
    if(status === 'success') {
      setTimeout(() => {
        closeDialog()
      }, 2000)
    }
  }, status)

  function ValidateEmail(mail) {
    return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  }

  const handleSubmit = () => {
    if(ValidateEmail(inputValue)) {
      submit()
    } else {
      setOnerror(true)
    }
  }

  const submit = () => {
    inputValue.indexOf("@") > -1 &&
    onValidated({
      EMAIL: inputValue
    })
  }

  return (
    <div className='speakeasy-menu__dialog__form'>
      {status === "success"
      ?
      <div className='success-msg'><img src={checkIcon} alt='arrow' style={{'marginRight': '12px'}} />Thank you</div>
      :
      <>
        <div className='speakeasy-menu__dialog-inner__title'>
          <div style={{'marginTop': '10px'}}>
            <div>Welcome!</div>
            <div style={{'marginTop': '4px'}}>Let us know you are here.</div>
          </div>
          <div onClick={closeDialog} style={{'cursor': 'pointer'}}><img src={closeIcon} alt='close' /></div>
        </div>
        <input
          ref={input}
          onChange={e => {setInputValue(e.target.value)}}
          placeholder='Enter Your Name'
        />
        <input
          ref={input}
          onChange={e => {setInputValue(e.target.value)}}
          placeholder='Enter Your Email'
        />
        <div className="errorMsg" style={{visibility: onError ? 'visible' : 'hidden'}}>Please enter valid email</div>
        <button
          className="button"
          onClick={handleSubmit}
        >Sign in</button>
        <div className='speakeasy-menu__dialog-inner__desc'>By signing in, you agree to subscribe to our newsletter. You can unsubscribe or change your preferences anytime by clicking the link in any emails you receive.</div>
      </>
      }
    </div>
  )
}

const SignInForm = ({ showDialog, handleCloseDialog }) => {
  const url = "https://rainraingallery.us14.list-manage.com/subscribe/post?u=1511c581d31380e2cb0fc607d&id=771f9dc947"

  return (
    <div className='speakeasy-menu__dialog' style={{'opacity': showDialog ? '1' : '0', 'visibility': showDialog ? 'visible' : 'hidden'}}>
      <div className='speakeasy-menu__dialog-inner'>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <Form
              status={status}
              message={message}
              onValidated={formData => subscribe(formData)}
              closeDialog={handleCloseDialog}
            />
          )}
        />
      </div>
    </div>
  )
}

export default SignInForm
