import { useEffect, useState } from "react"

const useScroll = () => {
  const [underFold, setUnderFold] = useState(false)
  const [menuVisible, setMenuVisible] = useState(true)

  function throttle(fn, wait) {
    var time = Date.now();
    return function() {
      if ((time + wait - Date.now()) < 0) {
        fn();
        time = Date.now();
      }
    }
  }

  useEffect(() => {
    let prevScrollpos = window.pageYOffset

    function handleScroll() {
      const currentScrollPos = window.pageYOffset
      if (prevScrollpos > currentScrollPos) {
        setMenuVisible(true)
      } else {
        setMenuVisible(false)
      }
      prevScrollpos = currentScrollPos

      if(prevScrollpos > window.innerHeight * 4) {
        setUnderFold(true)
      } else {
        setUnderFold(false)
      }
    }

    window.addEventListener('scroll', throttle(handleScroll, 100))

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return { underFold, menuVisible }
}

export { useScroll }