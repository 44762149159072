import React from 'react'
import FacebookIcon from '../../../svg/facebook.svg'
import TwitterIcon from '../../../svg/twitter.svg'
import EmailIcon from '../../../svg/email.svg'
import CopyIcon from '../../../svg/copy.svg'
import CloseIcon from '../../../svg/closeIcon.svg'

const Share = ({ visible, setShareVisible }) => {
  // ----- share functions -----
  function shareOnFacebook(){
    if(window) {
      const navUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href
      window.open(navUrl , '_blank')
    }
  }

  function shareOnTwitter() {
    if(window) {
      const navUrl = 'https://twitter.com/intent/tweet?text=' + window.location.href
      window.open(navUrl, '_blank')
    }
  }

  function copyToClipboard() {
    if(window) {
      navigator.clipboard.writeText(window.location.href)
    }
  }

  return (
    <div className='speakeasy-menu__share' style={{'display': visible && 'block'}}>
      <div className='speakeasy-menu__share__header'>
        <div>Share</div>
        <img onClick={() => setShareVisible(false)} className='speakeasy-menu__share__header__close' src={CloseIcon} alt='close' />
      </div>
      <div onClick={shareOnFacebook} className='speakeasy-menu__share__item'><div className='speakeasy-menu__share__item__icon'><img src={FacebookIcon} alt='facebook icon' /></div>Facebook</div>
      <div onClick={shareOnTwitter} className='speakeasy-menu__share__item'><div className='speakeasy-menu__share__item__icon'><img src={TwitterIcon} alt='facetwitterook icon' /></div>Twitter</div>
      <a className='speakeasy-menu__share__item' href='mailto:email@example.com'><div className='speakeasy-menu__share__item__icon'><img src={EmailIcon} alt='email icon' /></div>Email</a>
      <div onClick={copyToClipboard} className='speakeasy-menu__share__item'><div className='speakeasy-menu__share__item__icon'><img src={CopyIcon} alt='copy and paste icon' /></div>Copy Link</div>
    </div>
  )
}

export default Share
