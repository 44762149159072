import React from 'react'
import './styles.scss'

const Tags = ({ content }) => {
  console.log(content)
  return (
    <div className="tags" dangerouslySetInnerHTML={{ __html: content.childContentfulSpeakeasyTagsTagsTextNode.childMarkdownRemark.html }}></div>
  )
}

export default Tags