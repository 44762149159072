import React, { useState } from 'react'
import leftArrow from '../../../svg/left-arrow.svg'
import burgerIcon from '../../../svg/burgerIcon.svg'
import closeIcon from '../../../svg/cancel--white.svg'
import { Link } from 'gatsby'
import Share from './Share'
import { useScroll } from '../useScroll'

const MobileMenu = ({ top, handleShowDialog, handleCloseDialog }) => {
  const [expanded, setExpanded] = useState(false)
  const { menuVisible } = useScroll()

  function handleExpand() {
    document.body.style.overflow = 'hidden'
    setExpanded(true)
  }

  function handleUnExpand() {
    document.body.style.overflow = 'auto'
    setExpanded(false)
  }

  return (
    <div className='speakeasy-menu--mobile' style={{'opacity': menuVisible ? '1' : '0', 'pointerEvents': menuVisible ? 'visible' : 'none'}}>
      <div><Link to='/speakeasy'><img src={leftArrow} alt='arrow' style={{'marginRight': '12px'}} />Exit</Link></div>
      <div onClick={handleExpand} style={{'cursor': 'pointer'}}><img src={burgerIcon} alt='burger' /></div>
      <MobileMenuList visible={expanded} handleUnExpand={handleUnExpand} handleShowDialog={handleShowDialog} handleCloseDialog={handleCloseDialog} />
    </div>
  )
}

// ----- Mobile Menu -----
const MobileMenuList = ({ visible, handleUnExpand, handleShowDialog, handleCloseDialog }) => {
  const [shareVisible, setShareVisible] = useState(false)

  return (
    <>
      <div className={`speakeasy-menu--mobile__list ${visible && 'speakeasy-menu--mobile__list--is-visible'}`}>
        <img onClick={handleUnExpand} className='speakeasy-menu--mobile__list__close' src={closeIcon} alt='close' />
        <div className='speakeasy-menu--mobile__list__item' onClick={handleShowDialog}>Sign in the book</div>
        <div className='speakeasy-menu--mobile__list__item'><a href="mailto:hello@rainraingallery.com" target='_blank'>Send Inquiry</a></div>
        <div className='speakeasy-menu--mobile__list__item' onClick={() => setShareVisible(true)}>Share Article</div>
        <Share visible={shareVisible} setShareVisible={setShareVisible} />
      </div>
    </>
  )
}

export default MobileMenu