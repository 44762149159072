import React from 'react'
import './styles.scss'

const AdditionalInfo = ({ content }) => {
  const html = content.additionalInfo.childMarkdownRemark.html
  return (
    <>
      {html !== '<p>null</p>' && <div className="additional-info" dangerouslySetInnerHTML={{ __html: html }}></div>}
    </>
  )
}

export default AdditionalInfo