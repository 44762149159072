import React, { useEffect, useState } from 'react'
import './styles.scss'
import MobileMenu from './MobileMenu'
import SignInForm from './SignInForm'
import DesktopMenu from './DesktopMenu'
import Share from './Share'

const Menu = () => {
  const [showDialog, setShowDialog] = useState(false)
  const [showShare, setShowShare] = useState(false)

  function handleShowDialog() {
    document.body.style.overflow = 'hidden'
    setShowDialog(true)
  }

  function handleCloseDialog() {
    document.body.style.overflow = 'auto'
    setShowDialog(false)
  }

  return (
    <>
      <DesktopMenu handleShowDialog={handleShowDialog} setShowShare={setShowShare} />
      <MobileMenu handleShowDialog={handleShowDialog} handleCloseDialog={handleCloseDialog} />
      <SignInForm showDialog={showDialog} handleCloseDialog={handleCloseDialog} />
      <Share visible={showShare} />
    </>
  )
}

export default Menu
