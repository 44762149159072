import * as React from "react"

import upArrow from '../../../svg/speakeasy-top-icon.svg'
import './styles.scss'
import { useScroll } from '../useScroll'

const BackToTop = () => {
  const { underFold } = useScroll()

	const handleBackToTop = () => {
		window.scrollTo({top: 0, behavior: 'smooth'});
	}

  return (
    <div className={`back-to-top ${underFold && 'back-to-top--visible'}`} onClick={handleBackToTop}>
			<img data-type='icon' className='back-to-top__arrow' src={upArrow}/>
      <button
        className='back-to-top__button'
      >
				Top
			</button>
    </div>
  )
}

export default BackToTop
