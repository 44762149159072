import React from 'react'
import { Link } from 'gatsby'
import leftArrow from '../../../svg/left-arrow.svg'
import Share from './Share'
import { useScroll } from '../useScroll'

const DesktopMenu = ({ handleShowDialog }) => {
  const { menuVisible } = useScroll()

  return (
    <div className='speakeasy-menu'>
      <ul style={{'opacity': menuVisible ? '1' : '0', 'pointerEvents': menuVisible ? 'visible' : 'none'}}>
        <li><Link to='/speakeasy' style={{'display': 'flex'}}><img src={leftArrow} alt='arrow' style={{'marginRight': '12px'}} />Exit</Link></li>
        <li onClick={handleShowDialog}>Sign in the book</li>
        <li><a href="mailto:hello@rainraingallery.com" target='_blank'>Send Inquiry</a></li>
        <li className='share'>
          <div>Share Article</div>
          <Share />
        </li>
      </ul>
    </div>
  )
}

export default DesktopMenu
