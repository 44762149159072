import React, { useState } from 'react'
import './styles.scss'
import closeIcon from '../../../svg/closeIcon.svg'

const ImageGallery = ({ content }) => {
  function getType() {
    let type
    if(content.images.length === 1) {
      type = 'one-up'
    } else if(content.images.length === 2) {
      type = 'two-up'
    }else if(content.images.length === 3) {
      type = 'three-up'
    }
    return type
  }

  return (
    <div className='image-gallery' style={{'justifyContent': getType() === 'one-up' && 'center'}}>
      {
        content.images.map(image => (
          <ImageItem key={image.id} data={image} type={getType()} />
        ))
      }
    </div>
  )
}

const ImageItem = ({ data, type }) => {
  const { image, artistName, imageName, material, size } = data
  const [isFullScreen, setIsFullScreen] = useState(false)

  const handleCloseFullScreen = () => {
    setIsFullScreen(false)
  }

  return (
    <div className={`image-gallery__item ${type}`}>
      <img onClick={() => setIsFullScreen(true)} src={image.file.url} />
      <div className='image-gallery__info'>
        <div className='artist-name'>{artistName}</div>
        <div className='image-name'>{imageName}</div>
        <div className='material'>{material}</div>
        <div className='size'>{size}</div>
      </div>

      {isFullScreen && <FullScreenImage src={image.file.url} handleCloseFullScreen={handleCloseFullScreen} />}
    </div>
  )
}

const FullScreenImage = ({ src, handleCloseFullScreen }) => {
  return (
    <div className='image-gallery__fs-image'>
      <img className='image-gallery__fs-image__close' onClick={handleCloseFullScreen} src={closeIcon} alt='close icon' />
      <img className='image-gallery__fs-image__image' src={src} alt='full screen project image' />
    </div>
  )
}

export default ImageGallery