import React from "react"
import Seo from "../../components/seo"
import './styles.scss'
import Paragraphs from "./Paragraphs/index.js"
// import Video from "./Video/index.js"
import ImageGallery from './ImageGallery/index.js'
import Menu from "./Menu/index.js"
import { Link } from "gatsby"
import leftArrow from '../../svg/left-arrow.svg'
import rightArrow from '../../svg/right-arrow.svg'
import BackToTop from "./BackToTop"
import Tags from "./Tags"
import AdditionalInfo from "./AdditionalInfo"
import DownloadLink from './DownloadLink'

const Speakeasy = ({ pageContext }) => {
  const { node, previous, next } = pageContext.content
  const prevPage = (next && next.useTemplate) ? '/speakeasy/'+next.slug : null
  const nextPage = (previous && previous.useTemplate) ? '/speakeasy/'+previous.slug : null

  return (
    <div className="speakeasy-template">
      <Seo title={'Speakeasy - ' + node.title} />
      <Menu />
      {
        node.featuredImage &&
        <div className={`header-image ${node.isLargerHeaderImage && 'header-image--large'}`}>
          <img src={node.featuredImage.file.url} />
        </div>
      }
      <div className="header-info">
        {node.title && <div className="title">{node.title}</div>}
        {node.subtitle && <div className="subtitle">{node.subtitle}</div>}
        {node.date && <div className="date">{node.date}</div>}
      </div>
      <div>
      {
        node.buildingBlock &&
        node.buildingBlock.map(node => {
          switch(node.type) {
            case 'paragraphs':
              return <Paragraphs content={node} key={node.id} />
            // case 'video':
            //   return <Video content={node} key={node.id} />
            case 'image-gallery':
              return <ImageGallery content={node} key={node.id} />
            case 'tags':
              return <Tags content={node} key={node.id} />
            case 'additionalInfo':
              return <AdditionalInfo content={node} key={node.id} />
            case 'downloadLink':
              return <DownloadLink content={node} key={node.id} />
            default:
              // code block
          }
        })
      }
      </div>
      <div className="speakeasy-template__navigation">
        {
          prevPage
          ?
          <Link style={{'opacity': '1'}} className="speakeasy-template__navigation__left" to={prevPage}><img src={leftArrow} alt='arrow' style={{'marginRight': '12px'}} />Previous</Link>
          :
          <div style={{'opacity': '.1', 'cursor': 'not-allowed'}} className="speakeasy-template__navigation__left"><img src={leftArrow} alt='arrow' style={{'marginRight': '12px'}} />Previous</div>
        }

        {
          nextPage
          ?
          <Link style={{'opacity': '1'}} className="speakeasy-template__navigation__right" to={nextPage}>Next<img src={rightArrow} alt='arrow' style={{'marginLeft': '12px'}} /></Link>
          :
          <div style={{'opacity': '.1', 'cursor': 'not-allowed'}} className="speakeasy-template__navigation__right">Next<img src={rightArrow} alt='arrow' style={{'marginLeft': '12px'}} /></div>
        }
      </div>

      <BackToTop />
    </div>
  )
}

export default Speakeasy